const state = {
  cookieStr: "",
  appId: "",
};

const getters = {
  getAppId(state) {
    if (state.appId) {
      return state.appId
    } else {
      try {
        let appId = localStorage.getItem("appId")
        if (appId) {
          return appId
        }
      } catch (e) {
      }
    }
    return ""
  }
};

const mutations = {
};

const actions = {
  parseRequestParams({}, query) {
    if (query && query._usercookie && !state.cookieStr) {
      let cookieStr = '' + query._usercookie
      let cookieList = cookieStr.split(";")
      for (let i = 0; i < cookieList.length; i ++) {
        let cookieItem = cookieList[i].split("=")
        if (cookieItem.length === 2) {
          window.$cookies.set(cookieItem[0], cookieItem[1])
          console.log(cookieItem)
        }
      }

      state.cookieStr = cookieStr
      console.log(state.cookieStr)
    }

    if (query && query.app_id && !state.appId) {
      try {
        localStorage.setItem("appId", query.app_id)
      } catch (e) {
      }
      state.appId = query.app_id
    }
  },

};

export default {
  state,
  getters,
  actions,
  mutations,
};
