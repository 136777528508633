import axios from 'axios';
import store from "@/config/stores"

let appIdDefault = "tjst"
// 环境的切换
if (process.env.NODE_ENV == 'development') {
  // axios.defaults.baseURL = 'https://www.zanzandou.cn/api';
  // axios.defaults.baseURL = 'https://www.itong.app/api';
  // axios.defaults.baseURL = 'http://www.jidiaodiao.com/api';
  // axios.defaults.baseURL = 'http://localhost:8010/api';

  // axios.defaults.baseURL = 'http://wxapi.bigdoudou.com/api/wxapp';
  // axios.defaults.baseURL = 'http://192.168.1.53:8010/api/wxapp';
  axios.defaults.baseURL = 'http://192.168.31.160:8010/api/wxapp';
  // axios.defaults.baseURL = ' http://172.16.89.95:8010/api';
  // axios.defaults.baseURL = 'http://10.0.0.8:8010/api/wxapp';
  // axios.defaults.baseURL = 'http://www.canthelploving.info/api';
  // axios.defaults.baseURL = 'http://www.youjidiao.cn/api';
} else {
  axios.defaults.baseURL = '/api/wxapp';
}

// 超时时间
axios.defaults.timeout = 30000;
axios.defaults.withCredentials = true;

export function get(url, params) {
  if (!params) {
    params = {}
  }

  params.app_id = store.getters.getAppId || appIdDefault
  return new Promise((resolve, reject) => {
    axios
        .get(url, {
          params: params
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.data);
        });
  });
}

export function post(url, params, config) {
  if (!params) {
    params = {}
  }

  let postParams = {
    "app_id": store.getters.getAppId || appIdDefault
  }

  let configPost = {
    params: postParams,
  }

  if (config) {
    for (let k in config) {
      if (config.hasOwnProperty(k)) {
        configPost[k] = config[k];
      }
    }
  }

  return new Promise((resolve, reject) => {
    axios
        .post(url, params, configPost)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.data);
        });
  });
}
