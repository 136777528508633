import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import moment from "@/store/moment";
import base from "@/store/base";
import request_init from "@/store/request_init";
import goods from "@/store/goods";
import user_action from "@/store/user_action";
import ugc_item from "@/store/ugc_item";
import comment from "@/store/comment";
import account from "@/store/account";
import order from "@/store/order";
import brand from "@/store/brand";
import jssdk from "@/store/jssdk";

export default new Vuex.Store({
  modules: {
    moment,
    base,
    request_init,
    goods,
    user_action,
    ugc_item,
    comment,
    account,
    order,
    brand,
    jssdk,
  },
});
