import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './config/stores'
import VueCookies from 'vue-cookies';
import VuePageTitle from 'vue-page-title'

import IconSvg from './components/common/IconSvg'
import './icon/iconfont.js'
import {Lazyload, ImagePreview} from "vant";

Vue.component('icon-svg', IconSvg)
Vue.use(ImagePreview)
Vue.use(VuePageTitle)
Vue.use(Lazyload, {
  loading: "https://a1.wanwanshequ.com/static/public/loading-gif.gif",
  lazyComponent: true
});

VueCookies.config('90d')
Vue.use(VueCookies)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
