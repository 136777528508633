import {get, post} from "@/config/api";


const state = {
};

const getters = {};

const mutations = {};

const actions = {
  InitWxJsConfig({}, params) {
    return new Promise((resolve, reject) => {
      post(`/wechat/sdk_config/`, params).then((data) => {
        resolve(data);
      }, (error) => {
        reject(error)
      })
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

