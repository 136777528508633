import {get, post} from "@/config/api";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  GoodsUserRemark({}, params) {
    return new Promise((resolve, reject) => {
      post("/action/user/goods_remark/", params).then((data) => {
        resolve(data)
      }, (err) => {
        reject(err)
      })
    })
  },

  DoItemUserAction({}, params) {
    return new Promise((resolve, reject) => {
      post("action/user/item/", params).then((data) => {
        resolve(data)
      }, (err) => {
        reject(err)
      })
    })
  },

  UserItemActionStats({}, params) {
    return new Promise((resolve, reject) => {
      post("action/user/item_stats/", params).then((data) => {
        resolve(data)
      }, (err) => {
        reject(err)
      })
    })
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
