import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "root",
    redirect: "/shop",
  }, {
    path: '/goods_remark_create/',
    name: 'router_goods_remark_create_id',
    component: () => import(/* webpackChunkName: "data" */ '@/components/forum/moment/MomentCreateGoodsRemarkPage.vue'),
  }, {
    path: '/goods_remark_detail/',
    name: 'router_goods_remark_detail',
    component: () => import(/* webpackChunkName: "data" */ '@/components/forum/moment/MomentDetailPage.vue'),
  }, {
    path: '/goods_refund/',
    name: 'router_goods_refund',
    component: () => import(/* webpackChunkName: "data" */ '@/components/order/RefundPage.vue'),
  }, {
    path: "/article/:id",
    name: 'router_article',
    component: () => import(/* webpackChunkName: "data" */ '@/components/forum/article/ArticleDetailPage.vue'),
  }, {
    path: "/brand/detail",
    name: 'router_brand_detail',
    component: () => import(/* webpackChunkName: "data" */ '@/components/brand/BrandPage.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: "/",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {x: 0, y: 0}
    }
  }
})

export default router
