import {get, post} from "@/config/api";

const state = {
};

const getters = {
};

const mutations = {
};

const actions = {
  AccountInfoMine({}, params) {
    return new Promise((resolve, reject) => {
      get("account/mine/", params).then((data) => {
        resolve(data)
      }, (err)=> {
        reject(err)
      })
    })
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
