import {get, post} from "@/config/api";

const state = {
};

const getters = {
};

const mutations = {
};

const actions = {
  CreateMoment({}, params) {
    return new Promise((resolve, reject) => {
      post("/ugc/moment/create/", params).then((data) => {
        resolve(data)
      }, (err)=> {
        reject(err)
      })
    })
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
