import {get, post} from "@/config/api";

const state = {
};

const getters = {
};

const mutations = {
};

const actions = {
  GetUgcItem({}, params) {
    return new Promise((resolve, reject) => {
      get(`ugc/item/${params.id}/`, params).then((data) => {
        resolve(data);
      }, (error) => {
        reject(error)
      })
    });
  },

  UpdateItemStatus({}, params) {
    return new Promise((resolve, reject) => {
      post(`ugc/item/status/update/`, params).then((data) => {
        resolve(data);
      }, (error) => {
        reject(error)
      })
    });
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
