import {get, post} from "@/config/api";

const state = {
};

const getters = {
};

const mutations = {
};

const actions = {
  GetItemComment({}, params) {
    return new Promise((resolve, reject) => {
      get("comment/item/get/", params).then((data) => {
        resolve(data)
      }, (err)=> {
        reject(err)
      })
    })
  },

  PostItemComment({}, params) {
    return new Promise((resolve, reject) => {
      post("comment/item/post/", params).then((data) => {
        resolve(data)
      }, (err)=> {
        reject(err)
      })
    })
  },

  DeleteComment({}, params) {
    return new Promise((resolve, reject) => {
      post("comment/delete/", params).then((data) => {
        resolve(data)
      }, (err)=> {
        reject(err)
      })
    })
  }

};

export default {
  state,
  getters,
  actions,
  mutations,
};
