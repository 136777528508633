
import {get, post} from "@/config/api";

const state = {
};

const getters = {
};

const mutations = {
};

const actions = {
  UploadImage({}, params) {
    let config = {
      headers: { //添加请求头
        'Content-Type': 'multipart/form-data'
      }
    }
    return new Promise((resolve, reject) => {
      post("base/upload/image/", params, config).then((data) => {
        resolve(data)
      }, (err)=> {
        reject(err)
      })
    })
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
